<div
  [class.is-mobile]="!(isDesktop$ | async)"
  [class.has-fixed-footer]="false"
  [class.has-footer]="isFooterVisible$ | async"
  [class.scroll-disabled]="scrollDisabled$ | async"
  [class.toolbar-fixed]="isToolbarFixed$ | async"
  [class.navbar-fixed]="isNavbarFixed$ | async"
  [class.navbar-visible]="isNavbarVisible$ | async"
  [class.sidenav-collapsed]="sidenavCollapsed$ | async"
  [class.content-container]="containerEnabled$ | async"
  [class.with-search]="searchOpen$ | async"
  class="page-container"
>
  <hop-progress-bar></hop-progress-bar>

  <ng-container *ngTemplateOutlet="toolbarRef"></ng-container>

  <main class="front-content">
    <router-outlet></router-outlet>
  </main>
</div>
