import { Component } from '@angular/core';
import { da } from 'date-fns/locale';

@Component({
  selector: 'hop-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  isHamburgerOpen = false;

  onHamburgerButton() {
    this.isHamburgerOpen = this.isHamburgerOpen === false ? true : false;
  }
}
