<nav class="ml-3 mr-3 mx-auto p-3 font-bold bg-white z-50">
  <div class="flex justify-between space-x-20 items-center">
    <div routerLink="" class="z-30 cursor-pointer">
      <img src="../../assets/img/logo.png" alt="" />
    </div>
    <div class="hidden items-center space-x-10 uppercase md:flex">
      <div class="group">
        <a routerLink="">About</a>
        <div class="mx-1 group-hover:border-b group-hover:border-blue-500"></div>
      </div>

      <div class="group">
        <a routerLink="pricing">Pricing</a>
        <div class="mx-1 group-hover:border-b group-hover:border-blue-500"></div>
      </div>

      <div class="group">
        <a href="#">Help</a>
        <div class="mx-1 group-hover:border-b group-hover:border-blue-500"></div>
      </div>

      <div class="group">
        <a href="#">Login</a>
        <div class="mx-1 group-hover:border-b group-hover:border-blue-500"></div>
      </div>

      <a
        href="#"
        class="tracking-widest py-3 px-8 border-2 border-blue-500 bg-blue-500 rounded-lg text-white hover:bg-white hover:border-blue-500 hover:text-blue-500"
        >Sign up</a
      >
    </div>
    <button
      id="menu-btn"
      class="z-30 block md:hidden focus:outline-none hamburger"
      (click)="onHamburgerButton()"
      [ngClass]="isHamburgerOpen ? 'open' : 'hamburger'"
    >
      <span class="hamburger-top"></span>
      <span class="hamburger-middle"></span>
      <span class="hamburger-bottom"></span>
    </button>
  </div>
  <div class="absolute p-6 z-100 rounded-lg bg-slate-700 top-25 right-6 left-6" [ngClass]="isHamburgerOpen ? 'flex' : 'hidden'">
    <div class="flex flex-col space-y-6 text-center justify-center w-full font-bold text-white">
      <a routerLink="" class="hover:text-blue-500">About</a>
      <a routerLink="pricing" class="hover:text-blue-500">Pricing</a>
      <a href="#" class="hover:text-blue-500">Help</a>
      <a href="#" class="hover:text-blue-500">Login</a>
      <a
        href="#"
        class="tracking-widest py-3 px-8 border-2 border-blue-500 bg-blue-500 rounded-lg text-white hover:bg-slate-700 hover:border-blue-500 hover:text-blue-500"
        >Sign up</a
      >
    </div>
  </div>
</nav>
